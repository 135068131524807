import React, { useEffect, useState } from "react";
import { logout } from '../firebase/auth';
import Payments from "./Payments";
import styles from "./account.module.css";
import { GetDataAccount } from "./getDataAccount";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import {Buy} from "./Buy";
import { PaymentSuccess } from "./paymentSuccess";
//import Logger from "./Logger";



const Account = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        GetDataAccount(setUser);
    }, []);

    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <section className={styles.mainContent}>
                        <div className={styles.data}>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Folio:</label>
                                <h2 className={styles.info}>{user?.id || "Folio no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Nombre:</label>
                                <h2 className={styles.info}>{user?.name || "Nombre no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Apellido:</label>
                                <h2 className={styles.info}>{user?.lastName || "Apellido no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Dirección:</label>
                                <h2 className={styles.info}>{user?.address || "Dirección no disponible"} #{user?.houseNumber || "Numero no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Colonia:</label>
                                <h2 className={styles.info}>{user?.col || "Colonia no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Código Postal:</label>
                                <h2 className={styles.info}>{user?.cp || "Colonia no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Municipio:</label>
                                <h2 className={styles.info}>{user?.location || "Colonia no disponible"}</h2>
                            </div>
                            <div className={styles.dataItem}>
                                <label className={styles.label}>Teléfono:</label>
                                <h2 className={styles.info}>{user?.phone || "Teléfono no disponible"}</h2>
                            </div>
                        </div>
                        <aside className={styles.aside}>
                            <div className={styles.btnContent}>
                                <NavLink to="/Payments" className={styles.btn}>Historial de pagos</NavLink>
                                <NavLink to="/Buy" className={styles.btn}>Metodos de pago</NavLink>
                                <button className={styles.btn} onClick={logout}>Cerrar sesión</button>
                            </div>
                            {/*<div className={styles.linksContent}>
                                <a href="#" className={styles.links}>Contrato de membresía</a>
                                <a href="#" className={styles.links}>¿Cómo solicito mi servicio?</a>
                                <a href="#" className={styles.links}>¿Qué hacer en caso del fallecimiento de un familiar?</a>
                            </div>*/}
                        </aside>
                    </section>
                } />
                <Route path="/Payments" element={<Payments data={user} />} />
                <Route path="/Buy" element={<Buy user={user} />} />
                <Route path="/comprobante" element={<PaymentSuccess user={user} />} />
                {/*<Route path="/logger" element={<Logger data={user} />} />*/}
            </Routes>


        </BrowserRouter>

    );
}

export default Account;