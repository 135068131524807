import React, { useRef, useState } from "react";
import { getData } from "../firebase/auth";
import Loading from "./Loading";
import styles from "./login.module.css";
import ErrorMessage from "./errorMessage";


const Login = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        try {
          const error = await getData(email, password);
          if (error) {
            setErrorMessage(error);
          } else {
            setErrorMessage('');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setErrorMessage('Ha ocurrido un error inesperado. Por favor, intenta de nuevo.');
        } finally {
          setLoading(false);
        }
      };
    

    return (
        <section className={styles.mainContent}>
            <form className={styles.form} onSubmit={onSubmit}>
                <label className={styles.label}>Correo</label>
                <input type="email" placeholder="Correo" ref={emailRef} className={styles.input} />
                <label className={styles.label}>Contraseña</label>
                <input type="password" placeholder="Contraseña" ref={passwordRef} className={styles.input} />

                { errorMessage && <ErrorMessage error={errorMessage}/>}
                
                <button type="submit" className={styles.btn}>
                    {loading ? <Loading /> : <>Ingresar</>}
                </button>
            </form>
        </section>
    );
};

export default Login;
