import styles from "./errorMessage.module.css";
const ErrorMessage = ({error})=>{
    return(
        <div className={styles.errorContent}>
            <p>
                El correo o la contraseña son incorrectos
            </p>
        </div>
    );
}

export default ErrorMessage;